$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
.modal {
    display: block;
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-out;
  }
  
  .modal-container {
    width: 102%;
    height: 66%;
    background: linear-gradient(to bottom, #1F1F1F, #343434);
    border-radius: 30px 30px 0 0;
    position: absolute;
    bottom: -5px;
}


.modal-content {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    flex-direction: column;
}

.modal-image {
    /* width: 100px; */
    /* height: 100px; */
    border-radius: 8px;
    text-align: center;
    font-size: 4rem;
}

.modal-info {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
height: 100%;
width: 80%;
gap: 8px;
}

.modal-info p {
font-size: 16px;
}

.modal-title {
font-size: 22px;
margin-bottom: 16px;
}

.modal-button {
margin-top: 16px;
}
.free_boosts_title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}
.close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    color: #ababab;
    cursor: pointer;
    z-index: 10;
    background: #333333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}

.outer-container_boost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  
}

.container_glow {
  width: 100%;
  height: 100vh;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
}

.button-long {
  background: linear-gradient(69deg, #9E8EEB, #5574DF);
  color: white;
  padding: 15px;
  border-radius: 20px;
  font-size: 24px;
  margin: 10px;
  border: none;
  font-weight: 600;
}

.container_glow_modal{
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 5vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}

.boost_name{
  font-size: 13px;
    font-weight: 400;
}

.boost_img {
  max-width: 90px;
  max-height: 90px;
}
.shop-spcial-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    gap: 12px;
  }
  
  .shop-spcial-container > div {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 6px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    text-align: left;
    gap: 8px;
    z-index: 5;
}
.tab-section-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
}
.tab-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 480px;
    margin: 0 auto;
  }
  
  .tab-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    min-height: 64px;
    border-radius: 10px;
    padding-top: 16px;
}
  
  .tab-block-title {
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  
  .tab-subblock {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 16px;
    background: #272a2e;
    padding: 5px;
    border-radius: 20px;
}
  
  
  .rocket_boost{
    
    font-size: 100px;
    


}

.row_boosts {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  width: 100%;
  font-weight: 400;
  gap: 10px;
}
.img_boost{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 8px;
    font-size: 2.1rem;
}
.daily_boost {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  font-size: 10px;
  padding-top: 10px;
  background: #272a2e;
  border-radius: 20px;
}
.daily_boosts_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}
.boost_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.additional_boosts{
  width: 100%;
  font-size: 1.3rem;
  margin-top: 20px;
}
.header_background_boosts {
  position: absolute;
  width: 420px;
  height: 200px;
  background: radial-gradient(circle at center, #2cd09529, rgba(118, 58, 215, 0) 66%);
  overflow: visible;
  z-index: -1;
  top: 20%;
}

