$border-width: 3px;
$top-margin:40vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.league_container {
    width: 100%;
    background: #363443;
    height: 82vh;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding-bottom: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    gap: 3%;
}

.left_icon{
    width: 2rem !important;
    height: 2rem !important;
    position: absolute;
    left: 10%;
    top: 50%;
}
.right_icon{
    width: 2rem !important; 
    height: 2rem !important;
    position: absolute;
    right: 10%;
    top: 50%;
}

.cubok_img {
    width: 50vw;
    background: #1C1E23;
    border-radius: 20px;
}
.back{
    position: absolute;
    top: 20px;
    right: 20px;
    background: #4D4D4D;
    border-radius: 50%;
    color: #1C1E23;
}
.league_info_count{
    z-index: 10;
    position: relative;
    font-size: 20px;
    width: 100%;
    text-align: center;
}
.infocontainerleague{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.coin_icon {
    width: 18px;
    height: 18px;
}
.progress_bar_league {
    width: 100%;
    height: 15px;
    background: rgba(213, 213, 213, 0.4117647059);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
}
.navigation_container{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}