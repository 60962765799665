.divider {
    height: 66%;
    width: 2px;
    margin: 0 6px;
    background: linear-gradient(#00D1FF, #8165FF, #FA00FF);
}
.menu {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #282B30;
    border-radius: 10px;
    padding: 5px 12px;
    position: fixed;
    bottom: 20px;
}
.menu-button img {
    width: 40px;
    height: 40px;
}
.menu-button {
    border: none;
    padding: 0;
    width: 25%;
    max-width: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    height: 100%;
}
.menu_selected{
    background: #1C1E23;
    padding: 2px;
    border-radius: 10px;
}