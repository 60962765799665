.stat-block {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-top: 10px;
    color: white;
    max-width: 800px;
}
  
.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 1px;
  padding: 5px 0;
  background: #2b2e32;
  border-radius: 15px;
  width: 33%;
}

.stat_text {
  font-size: 0.9rem;
  font-weight: 300;
  margin-bottom: 5px;
}
  
  .stat-item > div {
    display: flex;
    align-items: center;
  }
  
  .stat-item img {
    width: 24px; 
    height: auto;
  }
  