$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_friends {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    overflow: scroll;
}

.container_glow_friends {
    width: calc(100% - 6px);
    height: 100vh;
    padding: 3vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    padding-top: 5vh;
}

.background_block {
    display: flex;
    align-items: center;
    background: #282B30;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
    width: 100%;
}
.ml-10{
    margin-left:10px
}
.friends_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}

.tab_block_friends {
    align-items: center;
    border-radius: 25px;
    padding: 16px;
    width: 100%;
    gap: 16px;
}

.flex_center{
    display: flex;
    align-items: center;
}

@media screen and (max-width:390px) 
{
    .container_glow_friends {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .frieds_task {
        align-items: center;
        background: #282b30;
        border-left: 1px solid #3300ff5e;
        border-radius: 10px;
        border-right: 1px solid #fa00ff87;
        box-shadow: 0 4px 8px #0003;
        color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 10px 0;
    
        .name{
            font-size: 13px;
            margin: 0;
            font-weight: 300;
            margin-bottom: 5px;
        }
        .flex-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .infoWrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        .coins{
            font-weight: 600;
        }
        .img_friends {
            display: flex;
            width: 40px;
            margin-right: 5px;
            background: linear-gradient(90deg, rgba(251, 79, 255, 0.5) 0%, rgba(37, 177, 255, 0.5) 100%);           
             padding: 5px;
            border-radius: 10px;
            align-items: center;
            font-size: 25px;
            margin-left: 7px;
            justify-content: center;
        }
        .league{
            margin-top: 5px;
            font-size: 10px;
        }
    }
} 
@keyframes pulse {
    0%, 100% {
      transform: scale(1);   
    }
    50% {
      transform: scale(1.02);  
    }
  }
  
  .invite_btn_container {
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: white;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
    gap: 30px;
}
.button_create {
    width: calc(100% + 32px);
    min-height: 48px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: -16px;
    font-size: 1.1rem;

}

.glow_create_team {
    position: absolute;
    width: 420px;
    height: 420px;
    background: radial-gradient(circle at center, #763AD7, rgba(118, 58, 215, 0) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    overflow: visible;
    z-index: -1;
}
.img_create_team{
    max-width: 35vw;
}
.container_glow_coming {
    width: 100%;
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #1F1F1F, #343434);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: center;
}
.button_create_create {
    width: 100%;
    min-height: 48px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: -16px;
    font-size: 1.1rem;
}
.subtext_create {
    font-size: 6vw;
    font-weight: 300;
}
.text_create {
    font-size: 6vw;
    font-weight: 300;
}
.text_container_create{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.container_glow_create {
    width: 100%;
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #1F1F1F, #343434);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: space-evenly;
    align-items: center;
}
.friend_img {
    height: 3rem;
    border-radius: 10px;
}

.friedns_row {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    font-weight: 500;
    font-size: 1rem;
    align-items: center;
}
.friedns_column {
    display: flex;
    flex-direction: column;
    width: 80%;
}
.completed{
    background: linear-gradient(90deg, #FB4FFF 0%, #25B1FF 100%) !important
}
.tab_header_background_friends {
    position: absolute;
    width: 420px;
    height: 275px;
    background: radial-gradient(circle at center, #9e8eeb5e, #9e8eeb00);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    overflow: visible;
    z-index: -1;
  }
  .friends_reload_container{
    width: 100%;
    margin: 15px 0;
    font-size: 1.2rem;
    font-weight: 200;
    display: flex;
    justify-content: space-between;
    
    img{
        width: 30px;
    }
}

.friends {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 200px;
}

.invite_link {
    background: linear-gradient(270deg, rgb(96, 118, 223), rgb(157, 141, 234));
    min-height: 40px;
    display: flex;
    align-items: flex-start;
    border-radius: 15px;
    width: 75%;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}
.invite_container {
    width: 90vw;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 100px;
}
.copy {
    background: linear-gradient(270deg, rgb(96, 118, 223), rgb(157, 141, 234));
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 20%;
    border-radius: 15px;


    img{
        width: 30px;
    }
}
.invite_text{
    color: #404040;
    font-size: 15px;
}
.containet_text_ing_friends{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
    width: 100%;

    img{
        height: 18px;
    }
}
.modal_friends{
    height: 60vh;
    background: #1C1E23;
    position: absolute;
    bottom: 0;
    border-top-left-radius: 30px;
    width: 100vw;
    border-top-right-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}
.img_modal {
    width: 90vw;
}
.text_friends{
    font-size: 2rem;
    font-weight: 500;
    margin-top: 5%;
}
.flow_shop{
    box-shadow: 0px 0px 190px 50px #9E8EEB;
    width: 124px;
    height: 8px;
    position: absolute;
    top: 42%;
}
.glow_friends_modal{
    box-shadow: 0px 0px 80px 30px #17FFE3;
    width: 124px;
    height: 0px;
    position: absolute;
    top: 42%;
    z-index: -1;
}
.friends_row {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    background: #272a2e;
    padding: 10px;
    border-radius: 10px;

    .friend_img {
        width: 40px;
        height: 35px;
        background: #000000;
        padding: 5px;
        border-radius: 10px;
       
    }
}
.background_black{
        width: 100vw;
        min-height: 171px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: black;
        position: fixed;
        bottom: 0;
}

.flex_friends {
    display: flex;
    gap: 15px;
    align-items: center;
}
.coin-icon-friends {
    width: 26px;
    height: 26px;
    margin-right: 2px;
}
.color{
    color: #9895A8;
}