$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(90deg, #FB4FFF, #25B1FF);

.container_glow_shop {
  padding: 3vw 3vw 0 3vw;
  margin: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.shop_grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 200px;
}

.modal-container-tasks {
  width: 102%;
  height: 70%;
  background: #1c1e23;
  border-radius: 45px;
  position: absolute;
  bottom: 0;
  left: -5px;
}

.modal-content_tasks {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.modal_image_tasks {
  width: 140px;
  text-align: center;
  font-size: 4rem;
  margin-bottom: 20px;
}

.coin-icon {
  width: 25px;
  height: 25px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}

.tab-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab_img_tasks {
  width: 120px;
}

.tab-title {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 8px;
}

.tasks_con {
  display: flex;
  align-items: center;
  background: #282B30;
  border-radius: 20px;
  padding: 15px;
  color: white;
}

.name {
  font-size: 15px;
  margin: 0;
  font-weight: 300;
  margin-bottom: 5px;
}

.img_friends {
  width: 45px;
  margin-right: 10px;
  border-radius: 10px;
}

.coins_reward {
  display: flex;
  align-items: center;
}

.coin_icon_task {
  width: 25px;
  height: 25px;
}
.tab_header_background_tasks {
  position: absolute;
  width: 420px;
  height: 420px;
  background: radial-gradient(circle at center, #2cd09554, rgba(118, 58, 215, 0) 66%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  overflow: visible;
  z-index: -1;
}
.daily_text{
  width: 100%;
  font-weight: 700;
  margin-bottom: 10px;
}

.button_tasks {
  background: linear-gradient(#9E8EEB, #5574DF);
  color: white;
  padding: 20px;
  border-radius: 20px;
  font-size: 25px;
  margin: 10px;
  border: none;
  font-weight: 600;
}

.done_tasks{
  color: #17D08E;
  pointer-events: none;
}
.coin_icon_modal_tasks {
  width: 30px;
}
.coins_container_tasks {
  display: flex;
  font-weight: 600;
  align-items: center;
  font-size: 1.3rem;
  justify-content: center;
}
 
.navigationTab{
  width: 100%;
  display: flex;
  border: 1px solid #393D49;
  border-radius: 15px;
  
  .selected{
    background: #272A2E;
    width: 33%;
    border-radius: 10px;
    padding: 8px
  }

  button{
    background: none;
    color: white;
    border: none;
    font-size: 15px;
    margin: 5px;
    width: 33%;
  }
}


.invite-container {
  background-color: #2c2f33;
  border-radius: 12px;
  color: white;
  font-family: Arial, sans-serif;
}

.invite-content {
  display: flex;
  align-items: center;
  padding: 20px;
}

.avatar {
  width: 50px; 
  border-radius: 50%;
  margin-right: 20px;
}

.invite-details {
  flex-grow: 1;
}

.invite-details 

    span {
    font-size: 18px;
    color: #B4B5B8;
  }
  maintext{
    color: white;
  }

.coin-reward {
  display: flex;
  align-items: center;
}

.coin-icon {
  width: 24px; 
  margin-right: 10px;
}

.claim-button {
  padding: 10px 20px;
  background-color: #35373F;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: #767A81;
  cursor: pointer;
}

.progress-bar-friends {
  background-color: #40444b;
  height: 20px;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 10px 10px 10px;
}

.progress-friends {
  height: 100%;
  background: linear-gradient(90deg, #A4E1A8 33%, #C88EF2 66%, #7068F6 100%);
  border-radius: 20px;
}
.image_top{
  background: #6C7CE2;
  border-radius: 50%;
  height: 50px;
  padding: 5px;
  width: 50px;
}
.flex_top {
  display: flex;
  gap: 15px;
  align-items: center;
  background: #272A2E;
  padding: 10px;
  border-radius: 10px;
}

.coin_top {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.statistic{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 40px;


  div {
    color: #9C99A8;
    font-size: 17px;
    z-index: 1;
  }

  span{
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    z-index: 1;
  }

}

.green_shadow {
  box-shadow: 0px 0px 200px 73px #2cd0955e;
  width: 40%;
  height: 0px;
  position: absolute;
  top: 50%;
  z-index: 0;
  transform: rotate(90deg);
}