.choosepet_container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    padding: 5vw;
    gap: 20px;
    background: #141826;
}
.d_flex_choose{
    display: flex;
    flex-direction: row;
}
.mt50{
    margin-top: 50px;
}
.info_pet{
    align-items: center;
    img{ width: 25px;}
}
.pet_name_container {
    display: flex;
    flex-direction: column;
    position: relative;
    background: linear-gradient(145deg, #9E8EEB, #5574DF);
    align-items: center;
    justify-content: flex-end;
    width: 125px;
    border-radius: 20px;

     img {
        width: 100px;
        position: absolute;
        top: -25%;
    }
}
.button_select {
    background: linear-gradient(145deg, #9E8EEB, #5574DF);
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;

    img{
        width: 22px;
    }
}
.info_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pets_containerP{
    margin-top: 30px;
    width: 100%;
    z-index: 1;

}
.choose_pet_img{
    width: 80vw;
    z-index: 1;

}
.choose_title{
    width: 100%;
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    z-index: 1;

}
.choose_description{
    color: #AA79B5;
    text-align: center;
    padding: 15px;
    font-size: 1.2rem;
    z-index: 1;

}
.choose_reward{
    color: white;
    font-weight: 500;
    z-index: 1;

}
.choose_coming{
    color: #4E4F50;
    font-size: 1.1rem;
    background: #272A2E;
    padding: 15px;
    border-radius: 10px;
    font-weight: 800;
    z-index: 1;
}
.rozoviy {
    box-shadow: 0px 0px 200px 73px #FF29C3;
    width: 40%;
    height: 0px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 0;
    transform: rotate(120deg);
}
.blue{
    box-shadow: 0px 0px 200px 73px #17FFE3;
    width: 40%;
    height: 0px;
    position: absolute;
    right: -21%;
    top: 50%;
    z-index: 0;
    transform: rotate(42deg);
}